<template>
  <div class="content">
    <div class="main-screen-section inside screen4"></div>
    <div class="container inside-page content-section">
      <h1>Диагностика мигрени<ReferenceLink text="1-2" href="#ref1" /></h1>
      <div class="content-text text-size-big mb40">
        <p>
          Мигрень — это инвалидизирующее неврологическое заболевание, для
          которого характерны периодические приступы головной боли
          продолжительностью от 4 до 72 часов.
        </p>
      </div>

      <div class="content-text text-size-middle mb50">
        <p>
          Приступы мигрени характеризуются односторонней пульсирующей болью,
          средней или тяжелой интенсивности, которая усиливается при обычной
          физической активности. Кроме того, приступы <br class="hidden-xs" />
          мигрени часто сопровождаются тошнотой, рвотой и повышенной
          чувствительностью к свету и звуку.
        </p>
        <p>
          Мигрень носит рецидивирующий и иногда хронический характер, связана с
          выраженной инвалидизацией и тяжким бременем для пациентов и их семей.
          Приступы мигрени с трудом поддаются лечению, поэтому особое значение
          имеет профилактика или уменьшение степени тяжести таких приступов.
        </p>
      </div>

      <div class="img-block">
        <img src="img/research.jpg" class="img-responsive" alt="img" />
      </div>

      <h3>Эпидемиология мигрени<ReferenceLink text="3-5" href="#ref3" /></h3>

      <div class="content-text text-size-small mb40">
        <p>
          Мигрень — это третье по распространенности заболевание в мире, которое
          наблюдается примерно у 1 млрд. людей. <br class="hidden-xs" />
          Во всем мире женщины подвержены большему риску развития мигрени, чем
          мужчины; в США риск развития мигрени у женщин в 3 раза превышает
          таковой у мужчин, при этом распространенность мигрени составляет ~18%
          у женщин и ~6% у мужчин.
        </p>
        <p>
          По данным ВОЗ, чаще всего заболевание проявляется в возрасте 25-55
          лет.
        </p>
        <p>
          По другим данным, мигрень чаще всего начинается в период полового
          созревания, а наибольшие страдания причиняет людям
          <br class="hidden-xs" />
          в возрасте 35–45 лет. Примерно в два раза чаще женщины страдают от
          мигрени, чем мужчины.
        </p>
        <p>
          Это объясняется гормональными факторами. Причиной мигрени является
          активация механизма в глубоких отделах мозга, который приводит к
          высвобождению вокруг нервов и кровеносных сосудов головы
          воспалительных веществ, вызывающих боль.
        </p>
      </div>
      <h3>Диагностические критерии<ReferenceLink text="2" href="#ref2" /></h3>
      <p>Для каждой формы мигрени применяются свои диагностические критерии.</p>
      <div class="content-text text-size-small diagnostic-criteria">
        <div class="migraine-type-criteria">
          <span class="migraine-type-title">
            Мигрень без ауры <span>(ранее простая мигрень):</span>
          </span>
          <ol class="criteria-list">
            <li>По меньшей мере пять приступов, отвечающих критериям 2–4.</li>
            <li>
              Продолжительность приступов 4-72 часа (без лечения или при
              неэффективном лечении).
            </li>
            <li>
              Головная боль имеет как минимум две из четырех следующих
              характеристик:
              <ul class="list-block">
                <li>Односторонняя локализация;</li>
                <li>Пульсирующий характер;</li>
                <li>Умеренная или высокая интенсивность боли;</li>
                <li>
                  Головная боль ухудшается при обычной физической активности или
                  заставляет избегать обычной физической активности (например,
                  ходьба или подъём по лестнице).
                </li>
              </ul>
            </li>
            <li>
              Головная боль сопровождается как минимум одним из следующих
              симптомов:
              <ul class="list-block">
                <li>Тошнота и/или рвота;</li>
                <li>Фотофобия и фонофобия.</li>
              </ul>
            </li>
            <li>Не соответствует другому диагнозу МКГБ-3.</li>
          </ol>
        </div>
        <div class="migraine-type-criteria">
          <span class="migraine-type-title">
            Мигрень с аурой
            <span
              >(ранее используемые термины: классическая мигрень;
              офтальмическая, гемипарестетическая, гемиплегическая или
              афатическая мигрень; ассоциированная мигрень; осложненная
              мигрень):</span
            >
          </span>
          <ol class="criteria-list">
            <li>По меньшей мере два приступа, отвечающих критериям 2 и 3.</li>
            <li>
              Один или более из следующих полностью обратимых симптомов ауры:
              <ul class="list-block">
                <li>зрительные;</li>
                <li>сенсорные;</li>
                <li>речевые и/или связанные с языком;</li>
                <li>двигательные;</li>
                <li>стволовые;</li>
                <li>ретинальные.</li>
              </ul>
            </li>
            <li>
              По меньшей мере три из следующих шести характеристик:
              <ul class="list-block">
                <li>
                  как минимум один симптом ауры постепенно нарастает в течение
                  >5 минут;
                </li>
                <li>два или более симптома ауры возникают последовательно;</li>
                <li>
                  каждый из отдельно взятых симптомов ауры длится 5-60 минут;
                </li>
                <li>
                  как минимум один из симптомов ауры является односторонним;
                </li>
                <li>как минимум один из симптомов ауры является позитивным;</li>
                <li>
                  в течение 60 минут после ауры или во время нее возникает ГБ.
                </li>
              </ul>
            </li>
            <li>Не соответствует другому диагнозу МКГБ-3.</li>
          </ol>
        </div>
        <div class="migraine-type-criteria">
          <span class="migraine-type-title">
            Возможная мигрень
            <span
              >(при мигренеподобных приступах отсутствует один из критериев,
              необходимых для выполнения всех критериев типа или подтипа
              мигрени, упомянутых выше, и не выполняются критерии для другого
              вида головной боли; ранее - мигренозное расстройство):</span
            >
          </span>
          <ol class="criteria-list">
            <li>
              Приступы, отвечающие всем критериям 1–4, кроме одного, для Мигрени
              без ауры или всем критериям 1–3, кроме одного, для Мигрени с
              аурой.
            </li>
            <li>
              Не отвечает критериям МКГБ-3 для любых других видов головной боли.
            </li>
            <li>Не соответствует другому диагнозу МКГБ-3.</li>
          </ol>
        </div>
        <div class="migraine-type-criteria">
          <span class="migraine-type-title"> Возможная мигрень без ауры: </span>
          <ol class="criteria-list">
            <li>
              Приступы, отвечающие всем критериям 1–4, кроме одного, для Мигрени
              без ауры.
            </li>
            <li>
              Не отвечает критериям МКГБ-3 для любых других видов головной боли.
            </li>
            <li>Не соответствует другому диагнозу МКГБ-3.</li>
          </ol>
        </div>
        <div class="migraine-type-criteria">
          <span class="migraine-type-title"> Возможная мигрень с аурой: </span>
          <ol class="criteria-list">
            <li>
              Приступы, отвечающие всем критериям 1–4, кроме одного, Мигрени с
              аурой или любым ее подтипам.
            </li>
            <li>
              Не отвечает критериям МКГБ-3 для любых других видов головной боли.
            </li>
            <li>Не соответствует другому диагнозу МКГБ-3.</li>
          </ol>
        </div>
        <div class="migraine-type-criteria">
          <span class="migraine-type-title">
            Мигрень с типичной аурой
            <span
              >(мигрень с аурой, при которой аура состоит из зрительных, и/или
              сенсорных и/или речевых / языковых симптомов, но без двигательных
              симптомов в виде слабости, и характеризуется постепенным
              развитием, продолжительность каждого симптома ауры не более одного
              часа, сочетанием позитивных и негативных признаков и их полной
              обратимостью):</span
            >
          </span>
          <ol class="criteria-list">
            <li>
              Приступы, отвечающие критериям мигрени с аурой и критерию 2,
              указанному ниже.
            </li>
            <li>
              Аура включает два следующих критерия:
              <ul class="list-block">
                <li>
                  Полностью обратимые зрительные, сенсорные и/или
                  речевые/языковые симптомы;
                </li>
                <li>
                  Отсутствие двигательных, стволовых или ретинальных симптомов.
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div class="migraine-type-criteria">
          <span class="migraine-type-title">
            Типичная аура с головной болью
            <span
              >(мигрень с типичной аурой, при которой аура сопровождается
              головной болью, или головная боль возникает в течение 60 минут
              после ауры; головная боль может иметь мигренозные характеристики
              или не иметь таковых):</span
            >
          </span>
          <ol class="criteria-list">
            <li>
              Приступы, отвечающие критериям Мигрени с типичной аурой и критерию
              2, указанному ниже.
            </li>
            <li>
              Головная боль с или без мигренозных характеристик сопровождает
              ауру или следует за аурой в течение 60 минут.
            </li>
          </ol>
        </div>
        <div class="migraine-type-criteria">
          <span class="migraine-type-title">
            Типичная аура без головной боли
            <span>
              (мигрень с типичной аурой, при которой аура не сопровождается и не
              сочетается с какой-либо головной болью): 
            </span>
          </span>
          <ol class="criteria-list">
            <li>
              Приступы, отвечающие критериям Мигрени с типичной аурой и критерию
              2, указанному ниже. 
            </li>
            <li>
              Головная боль отсутствует и не следует за аурой в течение 60
              минут.
            </li>
          </ol>
          <p class="comment">
            Комментарий: у некоторых пациентов типичная аура всегда
            сопровождается мигренью, но у многих пациентов, кроме того,
            возникают приступы ауры, сопровождающиеся менее выраженной головной
            болью или даже без головной боли. Ряд пациентов имеют исключительно
            типичную ауру без головной боли. При отсутствии головной боли,
            отвечающей критериям Мигрени без ауры, трудно поставить точный
            диагноз мигренозной ауры и отличить её от имитирующих ауру причин,
            которые могут сигнализировать о серьезном заболевании (например,
            транзиторной ишемической атаке), что часто требует дообследования.
            Когда аура возникает впервые после 40 лет, когда отмечаются
            исключительно симптомы выпадения (например, гемианопсия), или в
            случае пролонгированной, или, напротив, очень кратковременной ауры,
            диагностика должна быть направлена на исключение других заболеваний,
            особенно транзиторных ишемических атак.
          </p>
        </div>
        <div class="migraine-type-criteria">
          <span class="migraine-type-title"> Мигрень со стволовой аурой: </span>
          <ol class="criteria-list">
            <li>
              Приступы, отвечающие критериям Мигрени с аурой и критерию 2,
              указанному ниже.
            </li>
            <li class="sublist-item">
              Аура с двумя следующими признаками:
              <ul class="sub-list">
                <li>
                  <strong>
                    По крайней мере два следующих полностью обратимых стволовых
                    симптома:
                  </strong>
                  <ul class="list-block">
                    <li>Головокружение (вертиго);</li>
                    <li>Дизартрия;</li>
                    <li>Шум в ушах;</li>
                    <li>Гипоакузия;</li>
                    <li>Диплопия;</li>
                    <li>Атаксия, не связанная с сенсорным дефицитом;</li>
                    <li>Снижение уровня сознания (ШКГ≤13);</li>
                  </ul>
                </li>
                <li>
                  <strong>
                    Отсутствие двигательных или ретинальных симптомов. 
                  </strong>
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div class="migraine-type-criteria">
          <span class="migraine-type-title">
            Гемиплегическая мигрень
            <span>
              (мигрень с аурой, при которой аура состоит из зрительных, и/или
              сенсорных и/или речевых / языковых симптомов, но без двигательных
              симптомов в виде слабости, и характеризуется постепенным
              развитием, продолжительностью каждого симптома ауры не более
              одного часа, сочетанием позитивных и негативных признаков и их
              полной обратимостью):
            </span>
          </span>
          <ol class="criteria-list">
            <li>
              Приступы, отвечающие критериям Мигрени с аурой и критерию 2,
              указанному ниже.
            </li>
            <li>
              Аура, включающая оба следующих признака:
              <ul class="list-block">
                <li>
                  полностью обратимые двигательные нарушения в виде мышечной
                  слабости;
                </li>
                <li>
                  полностью обратимые зрительные, сенсорные и/или
                  речевые/языковые симптомы.
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div class="migraine-type-criteria">
          <span class="migraine-type-title">Ретинальная мигрень:</span>
          <ol class="criteria-list">
            <li>
              Приступы, отвечающие критериям Мигрени с аурой и критерию 2,
              указанному ниже.
            </li>
            <li class="sublist-item">
              Аура, характеризующаяся двумя следующими признаками:
              <ul class="sub-list">
                <li>
                  <strong>
                    По крайней мере два следующих полностью обратимых стволовых
                    симптома (например, сцинтилляции, скотома или слепота),
                    подтвержденные во время приступа одним или двумя следующими
                    способами:
                  </strong>
                  <ul class="list-block">
                    <li>Головокружение (вертиго);</li>
                    <li>Дизартрия;</li>
                    <li>Шум в ушах;</li>
                    <li>Гипоакузия;</li>
                    <li>Диплопия;</li>
                    <li>Атаксия, не связанная с сенсорным дефицитом;</li>
                    <li>Снижение уровня сознания (ШКГ 13);</li>
                  </ul>
                </li>
                <li>
                  <strong>
                    Выполняется по крайней мере два следующих условия:
                  </strong>
                  <ul class="list-block">
                    <li>Клиническое исследование поля зрения;</li>
                    <li>Постепенное нарастание в течение 5 минут;</li>
                    <li>Симптомы длятся 5–60 минут;</li>
                    <li>
                      Сопровождаются головной болью или головная боль возникает
                      в течение 60 минут после ауры;
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Не соответствует другому диагнозу МКГБ-3, и исключены другие
              возможные причины монокулярной преходящей слепоты.
            </li>
          </ol>
        </div>
        <p class="frequency-text">
          Помимо классификации мигрени по МКГБ-3, основанной на характере приступов мигрени, мигрень классифицируется по частоте возникновения приступов:
          <ul class="list-block">
            <li>Редкая эпизодическая мигрень (количество приступов меньше 4 в месяц);</li>
            <li>Частая эпизодическая мигрень (количество приступов от 4 до 8 в месяц); </li>
            <li>Хроническая мигрень (головная боль, возникающая в течение 15 или более дней в месяц на протяжении более трех месяцев, имеющая признаки мигренозной головной боли по крайней мере в течение 8 дней в месяц).</li>
          </ul>
        </p>
      </div>
      <ReferencesList :list="referencesList" />
    </div>
  </div>
</template>

<script>
import ReferenceLink from '../components/TevaReference/ReferenceLink.vue'
import ReferencesList from '../components/TevaReference/ReferencesList.vue'

export default {
  name: 'Diagnostics',
  components: {
    ReferenceLink,
    ReferencesList
  },
  metaInfo: {
    title: 'Диагностика',
    meta: [
      {
        name: 'description',
        content:
          'Головные боли одни из распространенных расстройств нервной системы и существенно нарушают качество жизни и трудоспособность'
      }
    ]
  },
  data() {
    return {
      referencesList: [
        {
          id: 'ref1',
          text: 'Headache Classification Committee of the International Headache Society : Cephalalgia. – 2018. – No 38 (1): 1-211'
        },
        {
          id: 'ref2',
          text: 'Клинический протокол «Диагностика и лечение мигрени», одобрен на заседании «Объединенной комиссии по качеству медицинских услуг (ОКК)» от 07.09.2023 г. (Протокол ОКК No188)'
        },
        {
          id: 'ref3',
          text: "Steiner TJ, Stovner LJ, Jensen R, et al; Lifting The Burden: the Global Campaign against Headache. Migraine remains second among the world's causes of disability, and first among young women: findings from GBD2019. J Headache Pain. 2020 Dec 2;21(1):137"
        },
        {
          id: 'ref4',
          text: 'Ashina M, Katsarava Z, Do TP, et al. Migraine: epidemiology and systems of care. Lancet. 2021 Apr 17;397(10283):1485-95.'
        },
        {
          id: 'ref5',
          text: 'Global Burden of Disease Study. Lancet 2015; 386: 743-800'
        }
      ]
    }
  }
}
</script>

<style scoped>
.diagnostic-criteria {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.migraine-type-criteria {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.migraine-type-criteria .migraine-type-title {
  font-weight: 400;
  color: #4e9e45;
  font-size: 1.625rem;
}
.migraine-type-criteria .migraine-type-title span {
  font-size: 1rem;
  color: #3c3c3c;
}
.criteria-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 16px;
  margin-bottom: 0px;
}
.criteria-list .list-block {
  margin-top: 10px;
}
.comment {
  margin-top: -10px;
  margin-bottom: 0px;
  font-size: 0.9rem;
  line-height: 1.2rem;
}
.sublist-item ul {
  margin-left: 12px;
}
.sublist-item .sub-list {
  margin-top: 10px;
}

.frequency-text .list-block{
  margin-top: 12px;
  padding-left: 16px;
}

@media (min-width: 320px) and (max-width: 767px) {
  .migraine-type-criteria .migraine-type-title {
    font-size: 1.2rem;
  }
}
</style>
